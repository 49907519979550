/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import "./index.css";
import { TfiQuoteLeft } from "react-icons/tfi";
import { FaArrowLeft } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa";
import img from "../../../assets/avatart/avatar3.jpg";
import img1 from "../../../assets/avatart/avatar2.jpg";
import { Button } from "react-bootstrap";

function index() {
  const [data, setData] = useState({
    img: img,
    name: "Mr. Amol Patil",
    title: "CEO, Zen Group",
    text1: "This success story is a testament to the power of hard work,passion, and the unwavering commitment to achieving one'sgoals. It chronicles the remarkable journey of Amol Patil, atalented Full Stack Developer whose determination and skillhave led to impressive accomplishments in the techindustry.!",
    text2: "Join us as we delve into the inspiring story of Amol Patil, exploring the path that transformed a passionate individual into a successful developer, making significant contributions to the field of technology and beyond."
  });

  const handleNext = () => {
    setData({
      img: img1,
      name: "Ms. Jane Doe",
      title: "CTO, Tech Innovators",
      text1: "Jane Doe's journey in the tech world is nothing short of inspirational.",
      text2: "Discover how Jane Doe overcame challenges and made significant contributions to the tech industry."
    });
  };

  const handlePrevious = () => {
    setData({
      img: img,
      name: "Mr. Amol Patil",
      title: "CEO, Zen Group",
      text1: "This success story is a testament to the power of hard work,passion, and the unwavering commitment to achieving one'sgoals. It chronicles the remarkable journey of Amol Patil, atalented Full Stack Developer whose determination and skillhave led to impressive accomplishments in the techindustry.!",
      text2: "Join us as we delve into the inspiring story of Amol Patil, exploring the path that transformed a passionate individual into a successful developer, making significant contributions to the field of technology and beyond."
    });
  };

  return (
    <>
      <section id="success-stories" className="success-stories">
        <div className="title">
          <h2>Success Stories</h2>
        </div>
        <div className="container">
          <div className="row pb-3">
            <div className="col-lg-6">
              <div className="success-div-data">
                <div className="success-img">
                  <img src={data.img} alt="img" />
                </div>
                <div className="success-img-deta">
                  <h6>{data.name}</h6>
                  <p>{data.title}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="success-text-data">
                <TfiQuoteLeft className="icon" />
                <div className="mx-5 success-text-data-1">
                  <p>
                    {data.text1}
                  </p>
                  <p>
                  {data.text2}
                  </p>
                  <div className="loading-success">
                    <div className="progress mt-3">
                      <div className="progress-bar"></div>
                    </div>
                    <div className="d-flex">
                      <Button className="button back mx-3" onClick={handlePrevious}>
                        <FaArrowLeft className="icon" />
                      </Button>
                      <Button className="button next" onClick={handleNext}>
                        <FaArrowRight className="icon" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default index;

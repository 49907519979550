import React from "react";
import "./index.css";
import Image1 from "./img/cross-platform-frameworks-abstract-concept-illustration 1.png";

function Index() {
  return (
    <React.Fragment>
      <section id="CourceHero" className="CourceHero">
        <div className="container about-hero px-0 pb-5">
          <div className="row no-gutters">
            <div className="col-sm-12 col-md-6 left-section px-5 pt-5">
              <h1 className="hdl-cc">
                "Discover Leading Companies and the Skills They Value"
              </h1>
              <p className="pt-3 sub-cc">
                "Explore top companies and find out the skills they seek. Align
                your learning with industry demands, wishlist your dream
                employers, and prepare to stand out."
              </p>
              {/* <div className="pt-5 px-5">
                <button className="btn btn-custom-cc px-5 py-3" type="submit">
                  Apply Now <i className="bi bi-chevron-compact-right"></i>
                </button>
              </div> */}
            </div>
            <div className="col-sm-12 col-md-6 right-section">
              <img src={Image1} alt="Hero" className="hero-img1" loading="lazy"/>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Index;

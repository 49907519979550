/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import "./index.css";
import Accordion from "react-bootstrap/Accordion";
import { useParams } from "react-router-dom";
import cousredetails from "../../../coursedetails.json"

function index() {
  const { id } = useParams();
  const course = cousredetails.find((course) => course.id === parseInt(id));
  return (
    <section id="curriculum" className="curriculum">
      <div className="title">
        <h2>Curriculum</h2>
      </div>
      <div className="container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header><h2>{course.acchead1}</h2><span>{course.accModule1}</span></Accordion.Header>
            <Accordion.Body>
              {course.accsub1}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
          <Accordion.Header><h2>{course.acchead2}</h2><span>{course.accModule2}</span></Accordion.Header>
            <Accordion.Body>
            {course.accsub2}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
          <Accordion.Header><h2>{course.acchead3}</h2><span>{course.accModule3}</span></Accordion.Header>
            <Accordion.Body>
            {course.accsub3}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
          <Accordion.Header><h2>{course.acchead4}</h2><span>{course.accModule4}</span></Accordion.Header>
            <Accordion.Body>
            {course.accsub4}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <p className="sub-title">After completing a course, you can download its videos for future reference.</p>
      </div>
    </section>
  );
}

export default index;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaFacebook, FaLinkedin, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaRegMessage } from "react-icons/fa6";
import { IoCallOutline } from "react-icons/io5";
import { MdOutlineLocationOn } from "react-icons/md";
import logo from "../../../assets/logo/ownprepwhite.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="py-4 footer">
      <Container>
        <Row>
          <Col sm={12} md={12} lg={3}>
            <div className="mt-5 footer-logo-main">
              <img src={logo} alt="OwnPrep" />
            </div>
          </Col>
          <Col sm={12} md={12} lg={6} className="text-center">
            <div className="mt-3">
              <ul className="list-unstyled">
                <li>
                  <a href="/" className="text-light">
                    {/* <FaHome className="icon" style={{ marginRight: "8px" }} /> */}
                    Home
                  </a>
                </li>
                <li>
                  <a href="/skillboost" className="text-light">
                    {/* <FaRocket className="icon" style={{ marginRight: "8px" }} /> */}
                    Skill Boost
                  </a>
                </li>
                <li>
                  <a href="/course" className="text-light">
                    {/* <FaBook className="icon" style={{ marginRight: "8px" }} /> */}
                    Course
                  </a>
                </li>
                <li>
                  <a href="/aboutcompany" className="text-light">
                    {/* <FaBuilding
                      className="icon"
                      style={{ marginRight: "8px" }}
                    /> */}
                    Employer
                  </a>
                </li>
                <li>
                  <a href="/free-bootcamp" className="text-light">
                    {/* <FaPhone className="icon" style={{ marginRight: "8px" }} /> */}
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href="/privacy_policy" className="text-light">
                    {/* <MdOutlinePrivacyTip
                      className="icon"
                      style={{ marginRight: "8px" }}
                    /> */}
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col sm={12} md={12} lg={3} className="contact-us">
            <div className="mt-3 contact-us-sub">
              <p className="text-light contact-us-title">Contact Us:</p>
              <ul className="contact-us-list">
                <li className="text-light">
                  <FaRegMessage
                    className="icon mx-2"
                    style={{ fontSize: "1.5em" }}
                  />
                  <p>info@ownprep.com</p>
                </li>
                <li className="text-light">
                  <IoCallOutline
                    className="icon mx-2"
                    style={{ fontSize: "1.5em" }}
                  />
                  <p>(748) 114-1437</p>
                </li>
                <li className="text-light">
                  <MdOutlineLocationOn
                    className="icon mx-2"
                    style={{ fontSize: "2.5em", marginTop: "-5px" }}
                  />
                  <p className="text-wrap">
                    Laxmi Vaibhav Building, W High Ct Rd, Behind Vaibhav Laxmi
                    Apartment, Laxminagar, Nagpur, Maharashtra 440020
                  </p>
                </li>
              </ul>
            </div>
          </Col>
          <div className="footer-year-main">
            <p className="footer-year text-center text-light">
              ©{currentYear} Ownprep, All Copyright reserved.
            </p>
            <div className="d-flex justify-content-center">
              <a href="/#" className="text-light mx-2">
                <FaFacebook className="icon" style={{ fontSize: "1.5em" }} />
              </a>
              <a href="/#" className="text-light mx-2">
                <FaLinkedin className="icon" style={{ fontSize: "1.5em" }} />
              </a>
              <a href="/#" className="text-light mx-2">
                <FaInstagram className="icon" style={{ fontSize: "1.5em" }} />
              </a>
              <a href="/#" className="text-light mx-2">
                <FaYoutube className="icon" style={{ fontSize: "1.5em" }} />
              </a>
            </div>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;

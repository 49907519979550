/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import Hero from "../../components/CourseDetails/Hero/index";
// import Testimonials from "../../components/Home/Testimonials";
import OverView from "../../components/CourseDetails/CourseOverview/index";
import Curriculum from "../../components/CourseDetails/Curriculum/index";
// import Best from "../../components/CourseDetails/Best/index";
// import FAQ from "../../components/CourseDetails/Faq/index";
// import Certificate from "../../components/CourseDetails/certificate/index";

function index() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Hero />
      <OverView />
      <Curriculum />
      {/* <Best /> */}
      {/* <Testimonials /> */}
      {/* <Certificate/> */}
      {/* <FAQ /> */}
    </div>
  );
}

export default index;

import React from "react";
import StudentLogin from "../../components/StudentLogin/index";

function index() {
  return (
    <>
      <section id="studentlogin">
        <StudentLogin />
      </section>
    </>
  );
}

export default index;

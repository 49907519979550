import React, { useState } from "react";
import { Button } from "react-bootstrap";
import img from "../../assets/company/raknew.png";
import img1 from "../../assets/company/liquid.png";
import img2 from "../../assets/company/realtime.png";
import img3 from "../../assets/company/jerseyi.png";
import img4 from "../../assets/company/petrolynk.png";
import img5 from "../../assets/company/mizybook.png";
import img6 from "../../assets/company/azlogic.png";
import img7 from "../../assets/company/wiriya.png";
// import img4 from "../../assets/company/capgemini.png";
// import img5 from "../../assets/company/infosys.png";
import { LuMapPin } from "react-icons/lu";
import { GiSquare } from "react-icons/gi";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import JobDetails from "../../JobDetails.json";

function Skills() {
  console.log(JobDetails, "job");
  // const [selectedSkill, setSelectedSkill] = useState("Data Science");

  // const handleSkillChange = (skill) => {
  //   setSelectedSkill(skill);
  // };

  // const skills = [
  //   {
  //     img: img,
  //     companyTitle: "RAK Softech Pvt Ltd",
  //     companyDesignation: "Data Analyst",
  //     location: "Nagpur",
  //     primarySkills: "data visualization, SQL, attention to detail, Excel, critical thinking, and business acumen",
  //   },
  //   {
  //     img: img1,
  //     companyTitle: "Facebook",
  //     companyDesignation: "Data Scientist",
  //     location: "San Francisco",
  //     primarySkills:
  //       "selfness, creativity, accessibility, credibility, adaptability, usability and value",
  //   },
  //   {
  //     img: img2,
  //     "companyTitle": "Accenture",
  //     companyDesignation: "Machine Learning Engineer",
  //     "location": "Seattle",
  //     primarySkills: "analytical thinking, problem-solving, Python, TensorFlow, scalability, and collaboration",
  //   },
  //   {
  //     img: img3,
  //     "companyTitle": "Amazon",
  //     companyDesignation: "AI Researcher",
  //     "location": "New York",
  //      primarySkills: "research, innovation, Python, deep learning, neural networks, and statistical analysis",
  //   }
  // ];

  // const skills1 = [
  //   {
  //     img: img4,
  //     companyTitle: "Capgemini",
  //     companyDesignation: "Business Analyst",
  //     location: "San Francisco",
  //     primarySkills: "data analysis, market research, strategic planning, SQL, Excel, and communication",
  //   },
  //   {
  //     img: img1,
  //     companyTitle: "Facebook",
  //     companyDesignation: "Product Manager",
  //     location: "San Francisco",
  //     primarySkills: "product lifecycle management, market analysis, stakeholder management, Agile methodologies, and leadership",
  //   },
  //   {
  //     img: img2,
  //     "companyTitle": "Accenture",
  //     companyDesignation: "Operations Manager",
  //     "location": "Seattle",
  //     primarySkills: "logistics, supply chain management, process optimization, project management, and leadership",
  //   },
  //   {
  //     img: img3,
  //     "companyTitle": "Amazon",
  //     companyDesignation: "Financial Analyst",
  //     "location": "New York",
  //     primarySkills: "financial modeling, forecasting, budget management, Excel, financial reporting, and strategic analysis",
  //   }
  // ];

  // const skills2 = [
  //   {
  //     img: img5,
  //     companyTitle: "Infosys",
  //     companyDesignation: "Software Engineer",
  //     location: "Bangalore",
  //     primarySkills: "Python, Java, system design, scalability, data structures, and algorithms",
  //   },
  //   {
  //     img: img1,
  //     companyTitle: "Facebook",
  //     companyDesignation: "Software Engineer",
  //     location: "San Francisco",
  //     primarySkills: "C++, Java, distributed systems, cloud computing, machine learning, and problem-solving",
  //   },
  //   {
  //     img: img2,
  //     "companyTitle": "Accenture",
  //     companyDesignation: "DevOps Engineer",
  //     "location": "Seattle",
  //     primarySkills: "AWS, automation, CI/CD, Docker, Kubernetes, and infrastructure as code",
  //   },
  //   {
  //     img: img3,
  //     "companyTitle": "Amazon",
  //     companyDesignation: "Systems Engineer",
  //     "location": "New York",
  //     primarySkills: "Windows Server, Active Directory, virtualization, network security, and troubleshooting",
  //   }
  // ];

  // const skills3 = [
  //   {
  //     img: img,
  //     companyTitle: "RAK Softech Pvt Ltd",
  //     companyDesignation: "UI/UX Designer",
  //     location: "Nagpur",
  //     primarySkills: "wireframing, prototyping, user research, Figma, usability testing, and interaction design",
  //   },
  //   {
  //     img: img1,
  //     companyTitle: "Facebook",
  //     companyDesignation: "Graphic Designer",
  //     location: "Mountain View",
  //     primarySkills: "Adobe Creative Suite, typography, branding, visual communication, and layout design",
  //   },
  //   {
  //     img: img2,
  //     "companyTitle": "Accenture",
  //     companyDesignation: "Product Designer",
  //     "location": "Seattle",
  //     primarySkills: "user-centered design, Sketch, visual storytelling, design systems, and cross-functional collaboration",
  //   },
  //   {
  //     img: img3,
  //     "companyTitle": "Amazon",
  //     companyDesignation: "Visual Designer",
  //     location: "Redmond",
  //     primarySkills: "Adobe Photoshop, Illustrator, creativity, attention to detail, and design principles",
  //   }
  // ];

  // const skills4 = [
  //   {
  //     img: img,
  //     companyTitle: "RAK Softech Pvt Ltd",
  //     companyDesignation: "Web Developer",
  //     location: "Nagpur",
  //     primarySkills: "HTML, CSS, JavaScript, React, usability, and performance optimization",
  //   },
  //   {
  //     img: img1,
  //     companyTitle: "Facebook",
  //     companyDesignation: "Web Developer",
  //     location: "San Francisco",
  //     primarySkills: "HTML, CSS, JavaScript, Angular, SEO, and responsive design",
  //   },
  //   {
  //     img: img2,
  //     "companyTitle": "Accenture",
  //     companyDesignation: "Web Developer",
  //     "location": "Seattle",
  //     primarySkills: "HTML, CSS, JavaScript, Vue.js, user experience, and cross-browser compatibility",
  //   },
  //   {
  //     img: img3,
  //     "companyTitle": "Amazon",
  //     companyDesignation: "Web Developer",
  //     "location": "New York",
  //     primarySkills: "HTML, CSS, JavaScript, ASP.NET, web security, and accessibility",
  //   }
  // ];

  const skills5 = [
    {
      img: img,
      companyTitle: "RAK Softech Pvt Ltd",
      companyDesignation: "MERN Stack",
      location: "Nagpur",
      primarySkills: "JavaScript, Node Js, React Js, Express Js, MongoDb",
    },
    {
      img: img1,
      companyTitle: "Liquid",
      companyDesignation: "Data Scientist",
      location: "New Delhi",
      primarySkills:
        "Python, Data Manipulation, Machine Learning, Artificial intellgence, ",
    },
    {
      img: img2,
      companyTitle: "Real Time Networking",
      companyDesignation: "Laravel Developer",
      location: "Bangalore",
      primarySkills: "PHP, SQL, Laravel, Postgres, PLSql ",
    },
    {
      img: img3,
      companyTitle: "Jerseyitech",
      companyDesignation: "AI/ML Developer",
      location: "Mumbai",
      primarySkills: "Python, MLOops, Deep Learning, TensorFlow",
    },
    {
      img: img4,
      companyTitle: "PetroLynks",
      companyDesignation: "Data Scientist",
      location: "Noida",
      primarySkills:
        "Data Visualization, MongoDb, Excel, critical thinking, and business acumen",
    },
    {
      img: img5,
      companyTitle: "Mizybook",
      companyDesignation: "Python Developer",
      location: "Jaipur",
      primarySkills: "Python, Python Framework, Django, Flask",
    },
    {
      img: img6,
      companyTitle: "azLogics",
      companyDesignation: "UI/UX Developer",
      location: "Nagpur",
      primarySkills: "Figma, HTML5, CSS3, JavaScript, Jquery ",
    },
    {
      img: img7,
      companyTitle: "Wiriya",
      companyDesignation: "React Nativ Developer",
      location: "Pune",
      primarySkills: "JavaScript, ES6, User Interface",
    },
  ];

  const [visibleCards, setVisibleCards] = useState(8);
  const [showMore, setShowMore] = useState(true);
  const handleToggle = () => {
    if (showMore) {
      setVisibleCards((prev) => prev + 8); // Increase number of visible cards
      if (visibleCards + 8 >= skills5.length) {
        setShowMore(false); // No more cards to show, switch to "Show Less"
      }
    } else {
      setVisibleCards(8); // Reset to show only the initial 8 cards
      setShowMore(true); // Switch back to "View More"
    }
  };

  return (
    <>
      <section id="skills" className="skills container">
        <div className="title">
          <h2>Your Opportunity Spotlight</h2>
        </div>

        {/* <div className="skills-button">
          <h5>Demanding Skills:</h5>
          <Button
            className={`button ${
              selectedSkill === "Data Science" ? "active" : ""
            }`}
            onClick={() => handleSkillChange("Data Science")}
          >
            Data Science
          </Button>
          <Button
            className={`button ${selectedSkill === "MBA" ? "active" : ""}`}
            onClick={() => handleSkillChange("MBA")}
          >
            MBA
          </Button>
          <Button
            className={`button ${
              selectedSkill === "Engineering" ? "active" : ""
            }`}
            onClick={() => handleSkillChange("Engineering")}
          >
            Engineering
          </Button>
          <Button
            className={`button ${selectedSkill === "Design" ? "active" : ""}`}
            onClick={() => handleSkillChange("Design")}
          >
            Design
          </Button>
          <Button
            className={`button ${
              selectedSkill === "Web Developer" ? "active" : ""
            }`}
            onClick={() => handleSkillChange("Web Developer")}
          >
            Web Developer
          </Button>
          <Button
            className={`button ${selectedSkill === "Commerce" ? "active" : ""}`}
            onClick={() => handleSkillChange("Commerce")}
          >
            Commerce
          </Button>
        </div> */}

        {/* Data Science */}
        {/* {selectedSkill === "Data Science" && (
          <div className="skills-card">
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              breakpoints={{
                425: {
                  slidesPerView: 1,
                  spaceBetween: 50,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
              }}
            >
              {skills.map((skill, index) => (
                <SwiperSlide key={index}>
                  <div className="card">
                    <div className="avatar">
                      <img src={skill.img} alt="Avatar" />
                    </div>
                    <div className="company-description">
                      <h4 className="company-title">{skill.companyTitle}</h4>
                      <h6 className="company-designation">
                        {skill.companyDesignation}
                      </h6>
                      <div className="company-destination">
                        <LuMapPin className="icon" /> <p>{skill.location}</p>
                      </div>
                      <div className="primary-skills">
                        <GiSquare className="icon" />
                        <p>
                          Primary skills:
                          <span className="mx-1">{skill.primarySkills}</span>
                        </p>
                      </div>
                    </div>
                    <div className="btn-div">
                      <Link to="/aboutjob">
                        <Button className="button">
                          View details <IoIosArrowForward />
                        </Button>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )} */}

        {/* MBA */}
        {/* {selectedSkill === "MBA" && (
          <div className="skills-card">
            <div className="skills-card">
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                breakpoints={{
                  425: {
                    slidesPerView: 1,
                    spaceBetween: 50,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                }}
              >
                {skills1.map((skill, index) => (
                  <SwiperSlide key={index}>
                    <div className="card">
                      <div className="avatar">
                        <img src={skill.img} alt="Avatar" />
                      </div>
                      <div className="company-description">
                        <h4 className="company-title">{skill.companyTitle}</h4>
                        <h6 className="company-designation">
                          {skill.companyDesignation}
                        </h6>
                        <div className="company-destination">
                          <LuMapPin className="icon" /> <p>{skill.location}</p>
                        </div>
                        <div className="primary-skills">
                          <GiSquare className="icon" />
                          <p>
                            Primary skills:
                            <span className="mx-1">{skill.primarySkills}</span>
                          </p>
                        </div>
                      </div>
                      <div className="btn-div">
                        <Link to="/aboutjob">
                          <Button className="button">
                            View details <IoIosArrowForward />
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )} */}

        {/* Engineering */}
        {/* {selectedSkill === "Engineering" && (
          <div className="skills-card">
            <div className="skills-card">
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                breakpoints={{
                  425: {
                    slidesPerView: 1,
                    spaceBetween: 50,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                }}
              >
                {skills2.map((skill, index) => (
                  <SwiperSlide key={index}>
                    <div className="card">
                      <div className="avatar">
                        <img src={skill.img} alt="Avatar" />
                      </div>
                      <div className="company-description">
                        <h4 className="company-title">{skill.companyTitle}</h4>
                        <h6 className="company-designation">
                          {skill.companyDesignation}
                        </h6>
                        <div className="company-destination">
                          <LuMapPin className="icon" /> <p>{skill.location}</p>
                        </div>
                        <div className="primary-skills">
                          <GiSquare className="icon" />
                          <p>
                            Primary skills:
                            <span className="mx-1">{skill.primarySkills}</span>
                          </p>
                        </div>
                      </div>
                      <div className="btn-div">
                        <Link to="/aboutjob">
                          <Button className="button">
                            View details <IoIosArrowForward />
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )} */}
        {/* Design */}
        {/* {selectedSkill === "Design" && (
          <div className="skills-card">
            <div className="skills-card">
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                breakpoints={{
                  425: {
                    slidesPerView: 1,
                    spaceBetween: 50,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                }}
              >
                {skills3.map((skill, index) => (
                  <SwiperSlide key={index}>
                    <div className="card">
                      <div className="avatar">
                        <img src={skill.img} alt="Avatar" />
                      </div>
                      <div className="company-description">
                        <h4 className="company-title">{skill.companyTitle}</h4>
                        <h6 className="company-designation">
                          {skill.companyDesignation}
                        </h6>
                        <div className="company-destination">
                          <LuMapPin className="icon" /> <p>{skill.location}</p>
                        </div>
                        <div className="primary-skills">
                          <GiSquare className="icon" />
                          <p>
                            Primary skills:
                            <span className="mx-1">{skill.primarySkills}</span>
                          </p>
                        </div>
                      </div>
                      <div className="btn-div">
                        <Link to="/aboutjob">
                          <Button className="button">
                            View details <IoIosArrowForward />
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )} */}
        {/* Web Developer */}
        {/* {selectedSkill === "Web Developer" && (
          <div className="skills-card">
            <div className="skills-card">
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                breakpoints={{
                  425: {
                    slidesPerView: 1,
                    spaceBetween: 50,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                }}
              >
                {skills4.map((skill, index) => (
                  <SwiperSlide key={index}>
                    <div className="card">
                      <div className="avatar">
                        <img src={skill.img} alt="Avatar" />
                      </div>
                      <div className="company-description">
                        <h4 className="company-title">{skill.companyTitle}</h4>
                        <h6 className="company-designation">
                          {skill.companyDesignation}
                        </h6>
                        <div className="company-destination">
                          <LuMapPin className="icon" /> <p>{skill.location}</p>
                        </div>
                        <div className="primary-skills">
                          <GiSquare className="icon" />
                          <p>
                            Primary skills:
                            <span className="mx-1">{skill.primarySkills}</span>
                          </p>
                        </div>
                      </div>
                      <div className="btn-div">
                        <Link to="/aboutjob">
                          <Button className="button">
                            View details <IoIosArrowForward />
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )} */}
        {/* Commerce */}
        {/* {selectedSkill === "Commerce" && (
          <div className="skills-card">
            <div className="skills-card">
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                breakpoints={{
                  425: {
                    slidesPerView: 1,
                    spaceBetween: 50,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                }}
              >
                {skills5.map((skill, index) => (
                  <SwiperSlide key={index}>
                    <div className="card">
                      <div className="avatar">
                        <img src={skill.img} alt="Avatar" />
                      </div>
                      <div className="company-description">
                        <h4 className="company-title">{skill.companyTitle}</h4>
                        <h6 className="company-designation">
                          {skill.companyDesignation}
                        </h6>
                        <div className="company-destination">
                          <LuMapPin className="icon" /> <p>{skill.location}</p>
                        </div>
                        <div className="primary-skills">
                          <GiSquare className="icon" />
                          <p>
                            Primary skills:
                            <span className="mx-1">{skill.primarySkills}</span>
                          </p>
                        </div>
                      </div>
                      <div className="btn-div">
                        <Link to="/aboutjob">
                          <Button className="button">
                            View details <IoIosArrowForward />
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )} */}

        <div className="row skills-card">
          {JobDetails.slice(0, visibleCards).map((skill, index) => (
            <div
              key={index}
              className="col-lg-3 col-md-4 col-sm-12 skills-col-hero"
            >
              <div className="card">
                <div className="avatar">
                  <img src={skill.imgUrl} alt="Avatar" />
                </div>
                <div className="company-description">
                  <h4 className="company-title">{skill.title}</h4>
                  <h6 className="company-designation">{skill.designation}</h6>
                  <div className="company-destination">
                    <LuMapPin className="icon" /> <p>{skill.lacation}</p>
                  </div>
                  <div className="primary-skills">
                    <GiSquare className="icon" />
                    <p>
                      Primary skills:
                      <span className="mx-1">{skill.primaryskills}</span>
                    </p>
                  </div>
                </div>
                <div className="btn-div">
                  <Link to={`/aboutjob/${skill.id}`}>
                    <Button className="button">
                      View details <IoIosArrowForward />
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        {(visibleCards < skills5.length || !showMore) && (
          <div className="text-center mt-4">
            <Button onClick={handleToggle} className="toggle-button">
              {showMore ? "View More" : "Show Less"}
              <IoIosArrowForward />
            </Button>
          </div>
        )}
      </section>
    </>
  );
}

export default Skills;

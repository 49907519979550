import React from "react";
import "./index.css";
import img1 from "../../../assets/img/benefits/ben1.png";
import img2 from "../../../assets/img/benefits/ben2.png";
import img3 from "../../../assets/img/benefits/ben3.png";

function index() {
  return (
    <>
      <section id="benefits" className="benefits">
        <div className="title">
          <h2>Benefits For Company</h2>
        </div>
        <div className="container benefits-1">
          <div className="row ">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="benefits-img-div">
                <div className="benefits-img">
                  <img src={img1} alt="" />
                </div>
              </div>
              <div className="benefits-deta">
                <h5>Access To Talent</h5>
                <p>
                  Enables you to attract, recruit, and retain skilled
                  professionals who align with your company's values, driving
                  growth and innovation. We connect you with top talent to
                  ensure long-term success.{" "}
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="benefits-img-div">
                <div className="benefits-img">
                  <img src={img2} alt="" />
                </div>
              </div>

              <div className="benefits-deta">
                <h5>Streamlined Recruitment</h5>
                <p>
                  Simplifies hiring by optimizing processes, reducing
                  time-to-hire, and ensuring a seamless experience. We connect
                  you with the right talent efficiently and effectively for
                  faster growth.{" "}
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="benefits-img-div">
                <div className="benefits-img">
                  <img src={img3} alt="" />
                </div>
              </div>

              <div className="benefits-deta">
                <h5>Insights</h5>
                <p>
                  Empower your business with data-driven decisions, offering
                  deep analysis and understanding of market trends. We provide
                  actionable intelligence to help you stay ahead and drive
                  informed strategies.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default index;

import React, { useState, useEffect } from "react";
import { BiMenu } from "react-icons/bi";
import { HashLink } from "react-router-hash-link";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/logo/ownprep.png";
import { Button } from "react-bootstrap";

const Header = () => {
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("Home");

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    console.log("Mobile menu toggled");
  };

  useEffect(() => {
    // Update active link based on the current path
    const path = location.pathname;
    const hash = location.hash;
    const link = path + hash;
    setActiveLink(link);
  }, [location]);

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
    if (mobileMenuOpen) {
      toggleMobileMenu();
    }
    window.scrollTo(0, 0);
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -150;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  window.addEventListener("scroll", function () {
    var header = document.getElementById("header");
    if (window.scrollY > 0) {
      header.classList.add("header-blurred");
    } else {
      header.classList.remove("header-blurred");
    }
  });

  // window.addEventListener('scroll', function() {
  //   var header = document.getElementById('header');
  //   if (window.scrollY > 0) {
  //     header.classList.add('header-scrolled');
  //   } else {
  //     header.classList.remove('header-scrolled');
  //   }
  // });

  return (
    <>
      <header
        id="header"
        className="fixed-top d-flex align-items-center header-transparent"
      >
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">
            <h1>
              <Link
                to="/"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <img src={logo} alt="OwnPrep" />
              </Link>
            </h1>
          </div>
          <nav
            id="navbar"
            className={`navbar ${mobileMenuOpen ? "mobile-nav-open" : ""}`}
          >
            <ul>
              <li>
                <HashLink
                  className={`nav-link scrollto ${
                    activeLink === "/" ? "active" : ""
                  }`}
                  smooth
                  to="/"
                  onClick={() => handleLinkClick("/")}
                >
                  Home
                </HashLink>
              </li>
              <li>
                <Link
                  className={`nav-link scrollto ${
                    activeLink === "/skillboost" ? "active" : ""
                  }`}
                  smooth
                  to="/skillboost"
                  onClick={() => handleLinkClick("/skillboost")}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  Skill Boost
                </Link>
              </li>
              {/* <li>
                <Link
                  className={`nav-link scrollto ${
                    activeLink === "/course" || activeLink === "/coursedetails/:id" ? "active" : ""
                  }`}
                  to="/course"
                  onClick={() => handleLinkClick("/course")}
                >
                  Courses
                </Link>
              </li> */}
              <li>
                <Link
                  className={`nav-link scrollto ${
                    activeLink === "/course" ||
                    /^\/coursedetails\/\d+$/.test(activeLink)
                      ? "active"
                      : ""
                  }`}
                  to="/course"
                  onClick={() => handleLinkClick("/course")}
                >
                  Courses
                </Link>
              </li>

              <li>
                {/* <HashLink
                  className={`nav-link scrollto ${
                    activeLink === "/#" ? "active" : ""
                  }`}
                  smooth
                  to="/aboutcompany"
                  onClick={() => handleLinkClick("/#about")}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  About Us
                </HashLink> */}
                <Link
                  className={`nav-link scrollto ${
                    activeLink === "/aboutcompany" ? "active" : ""
                  }`}
                  to="/aboutcompany"
                  onClick={() => handleLinkClick("/aboutcompany")}
                >
                  Employer
                </Link>
              </li>
              <li>
                <HashLink
                  className={`nav-link scrollto ${
                    activeLink === "/studentlogin" ? "active" : ""
                  }`}
                  smooth
                  to="/studentlogin"
                  onClick={() => handleLinkClick("/studentlogin")}
                >
                  Student
                </HashLink>
              </li>
              <li>
                <Link to="/free-bootcamp" className="nav-link">
                  <Button className="button">FREE BOOTCAMP</Button>
                </Link>
              </li>
              {/* <li>
                <HashLink
                  className={`nav-link scrollto ${
                    activeLink === "/#testimonials" ? "active" : ""
                  }`}
                  smooth
                  to="/#testimonials"
                  onClick={() => handleLinkClick("/#testimonials")}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  Testimonials
                </HashLink>
              </li> */}
            </ul>
            <BiMenu className="mobile-nav-toggle" onClick={toggleMobileMenu} />
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;

import React from "react";
import "./index.css";
import playbtn from "../../assets/img/playstore/PlayButton.png";
import img1 from "../../assets/img/playstore/Group 429.png";
import img2 from "../../assets/img/playstore/Group 428.png";

function index() {
  return (
    <>
      <section id="Play-banner" className="Play-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 Play-banner-div">
              <div className="baneer-text">
                <h1>
                  Sharpen Your Skills with Practice Tests on Our Mobile App.
                </h1>
                <p>
                  Prepare for success anytime, anywhere. Download our Android
                  app and start taking practice tests to boost your confidence
                  and readiness.
                </p>
              </div>
              <div className="play-btn">
                <a href="/#">
                  <img src={playbtn} alt="" />
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="app-img">
                <img src={img1} className="img1" alt="" />
                <img src={img2} className="img2" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default index;

import React from "react";
import { FaCheckCircle, FaInfoCircle } from "react-icons/fa"; 
import {  MdPrivacyTip } from "react-icons/md"; // Material Icons
import "./index.css";

function Index() {
  return (
    <>
      <section className="section-privacyPolicy" id="privacyPolicy" style={{paddingTop:"8rem"}}>
        <div className="container-privacyPolicy">
          <div className="title-privacyPolicy">
            <h2>
              <MdPrivacyTip className="icon-title" />
              Privacy Policy for Ownprep
            </h2>
          </div>
          <div className="content-privacyPolicy">
            <p>
              At <strong>Ownprep</strong>, accessible from{" "}
              <a
                href="https://www.ownprep.com"
                target="_blank"
                rel="noreferrer"
                className="link-privacyPolicy"
              >
                https://www.ownprep.com
              </a>
              , one of our main priorities is the privacy of our visitors. This Privacy
              Policy document outlines the types of information that are collected and
              recorded by <strong>Ownprep</strong> and how we use it.
            </p>

            <p>
              If you have additional questions or require more information about our Privacy Policy,
              do not hesitate to{" "}
              <a href="mailto:support@ownprep.com" className="link-contact">
                contact us
              </a>
              .
            </p>

            <p>
              This Privacy Policy applies only to our online activities and is valid
              for visitors to our website with regards to the information they
              share and/or collect in <strong>Ownprep</strong>. This policy is not
              applicable to any information collected offline or via channels other than this website.
            </p>

            <h3>
              <FaCheckCircle className="icon-section" />
              Consent
            </h3>
            <p>
              By using our website, you hereby consent to our Privacy Policy and agree to its terms.
            </p>

            <h3>
              <FaCheckCircle className="icon-section" />
              Information We Collect
            </h3>
            <p>
              The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear at the point we ask you to provide it.
            </p>
            <p>
              - If you contact us directly, we may receive additional information such as your name, email address, phone number, and message content.
            </p>
            <p>
              - When you register for an Account, we may ask for your contact information, including your name, email address, and phone number.
            </p>

            <h3>
              <FaCheckCircle className="icon-section" />
              How We Use Your Information
            </h3>
            <p>We use the information we collect in various ways, including to:</p>
            <ul className="list-privacyPolicy">
              <li>Provide, operate, and maintain our website</li>
              <li>Improve, personalize, and expand our services</li>
              <li>Understand and analyze how you use our website</li>
              <li>Develop new products, services, features, and functionality</li>
              <li>Communicate with you for customer support, updates, and other purposes</li>
              <li>Send you emails and promotional content</li>
              <li>Prevent fraud and enhance security</li>
            </ul>

            <h3>
              <FaInfoCircle className="icon-section" />
              Log Files
            </h3>
            <p>
              <strong>Ownprep</strong> follows a standard procedure of using log files. The information collected by log files includes IP addresses, browser type, ISP, date/time stamp, referring pages, and click counts. These are not linked to any personally identifiable information. The data is used to analyze trends and manage the site.
            </p>

            <h3>
              <FaInfoCircle className="icon-section" />
              Cookies and Web Beacons
            </h3>
            <p>
              Like other websites, <strong>Ownprep</strong> uses cookies to store information, including visitors’ preferences and the pages they visited on our site. This data is used to improve user experience by customizing web content based on browser type and other preferences.
            </p>

            <h3>
              <FaInfoCircle className="icon-section" />
              Google DoubleClick DART Cookie
            </h3>
            <p>
              Google is a third-party vendor on our site. It uses DART cookies to serve ads based on visitors’ activity on our website. Visitors can opt out by visiting Google's Ads settings.
            </p>

            <h3>
              <FaInfoCircle className="icon-section" />
              Third-Party Privacy Policies
            </h3>
            <p>
              <strong>Ownprep's</strong> Privacy Policy does not apply to third-party advertisers or websites. We recommend reviewing their privacy policies for further details. You can manage your cookies directly through your browser.
            </p>

            <h3>
              <FaInfoCircle className="icon-section" />
              GDPR Data Protection Rights
            </h3>
            <p>We aim to ensure that you are fully aware of your data protection rights:</p>
            <ul className="list-privacyPolicy">
              <li>
                <strong>The right to access:</strong> You can request copies of your personal data.
              </li>
              <li>
                <strong>The right to rectification:</strong> You have the right to request corrections to inaccurate information.
              </li>
              <li>
                <strong>The right to erasure:</strong> You can request the deletion of your personal data, under certain conditions.
              </li>
              <li>
                <strong>The right to restrict processing:</strong> You can request that we limit the processing of your data.
              </li>
              <li>
                <strong>The right to object:</strong> You can object to how we process your data.
              </li>
              <li>
                <strong>The right to data portability:</strong> You can request that we transfer your data to another entity.
              </li>
            </ul>
            <p>If you make a request, we have one month to respond.</p>

            <h3>
              <FaInfoCircle className="icon-section" />
              Children's Privacy
            </h3>
            <p>
              We are committed to protecting children’s privacy. <strong>Ownprep</strong> does not knowingly collect any personally identifiable information from children under the age of 13. If you believe that your child has provided such data, please contact us immediately, and we will remove it promptly.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;

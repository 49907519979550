import React from "react";
import Marquee from "react-fast-marquee";
import img1 from "../../../assets/company/areliya.png";
import img2 from "../../../assets/company/azlogic.png";
import img3 from "../../../assets/company/broadifi.png";
import img4 from "../../../assets/company/wiriya.png";
import img5 from "../../../assets/company/cootagecart.png";
import img6 from "../../../assets/company/jerseyi.png";
import img7 from "../../../assets/company/simaji.png";
import img8 from "../../../assets/company/realtime.png";
import img9 from "../../../assets/company/raknew.png";
import img10 from "../../../assets/company/petrolynk.png";
import img11 from "../../../assets/company/betasys_ai2.jpeg";
import "./index.css";

function index() {
  return (
    <>
      <section id="company-card-marquee"> 
        <div className="container company-card-marquee">
          <div className="card shadow-sm">
            <div className="card-body">
              <Marquee gradient={false} speed={60} loop={0} play={true} direction="left">
                <div className="d-flex justify-content-around align-items-center">
                  <img src={img1} alt="" className="mx-4" />
                  <img src={img2} alt="" className="mx-4" />
                  <img src={img3} alt="" className="mx-4" />
                  <img src={img4} alt="" className="mx-4" />
                  <img src={img5} alt="" className="mx-4" />
                  <img src={img6} alt="" className="mx-4" />
                  <img src={img7} alt="" className="mx-4" />
                  <img src={img8} alt="" className="mx-4" />
                  <img src={img9} alt="" className="mx-4" />
                  <img src={img10} alt="" className="mx-4" />
                  <img src={img11} alt="" className="mx-4" />
                </div>
              </Marquee>
            </div>
          </div>
          <div className="text-center mt-3">
            <p>More than 500 companies have partnered with us.</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default index;

// import React from "react";
// import img1 from "../../assets/img/testimonials/test1.png";
// import img2 from "../../assets/img/testimonials/test2.png";
// import img3 from "../../assets/img/testimonials/test3.png";
// import "bootstrap/dist/css/bootstrap.min.css";

// function Testimonials() {
//   const testimonialsData = [
//     {
//       name: "Parikshit Desai",
//       position: "CEO, Markin",
//       image: img1,
//       text: "Working with this team has been an absolute pleasure. Their expertise in React and MongoDB helped us revamp our entire platform, resulting in a 30% increase in user engagement. Highly recommended!",
//     },
//     {
//       name: "Prerana Chafekar",
//       position: "CEO, Markin",
//       image: img2,
//       text: "The admin dashboard they developed for our learning platform is simply outstanding. It's intuitive, fast, and has greatly improved our efficiency. Their skills in full-stack development are top-notch.",
//     },
//     {
//       name: "Akshay Pandey",
//       position: "CEO, Markin",
//       image: img3,
//       text: "The ERP solution implemented by this developer has transformed our college's operations. From student management to financial tracking, everything is seamless and integrated. A great job done!.",
//     },
//   ];
//   return (
//     <>
//       <section id="testimonials" className="testimonials">
//         <div className="title">
//           <h2>Testimonials</h2>
//         </div>
//         <div className="container">
//           <div className="row">
//             {testimonialsData.map((testimonial, index) => (
//               <div key={index} className="col-md-4">
//                 <div className="card testimonial-card text-center p-4 border-0">
//                   <div className="testimonial-image-wrapper">
//                     <img
//                       src={testimonial.image}
//                       alt={testimonial.name}
//                       className="testimonial-image rounded-circle"
//                     />
//                   </div>
//                   <div className="card-body">
//                     <p className="card-text">{testimonial.text}</p>
//                     <h5 className="card-title">{testimonial.name}</h5>
//                     <p className="card-subtitle text-muted">
//                       {testimonial.position}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>
//     </>
//   );
// }

// export default Testimonials;



import React from "react";
import img1 from "../../assets/img/testimonials/test1.png";
import img2 from "../../assets/img/testimonials/test2.png";
import img3 from "../../assets/img/testimonials/test3.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

function Testimonials() {
  const testimonialsData = [
    {
      name: "Parikshit Desai",
      position: "CEO, Markin",
      image: img1,
      text: "Working with this team has been an absolute pleasure. Their expertise in React and MongoDB helped us revamp our entire platform, resulting in a 30% increase in user engagement. Highly recommended!",
    },
    {
      name: "Prerana Chafekar",
      position: "CEO, Markin",
      image: img2,
      text: "The admin dashboard they developed for our learning platform is simply outstanding. It's intuitive, fast, and has greatly improved our efficiency. Their skills in full-stack development are top-notch.",
    },
    {
      name: "Akshay Pandey",
      position: "CEO, Markin",
      image: img3,
      text: "The ERP solution implemented by this developer has transformed our college's operations. From student management to financial tracking, everything is seamless and integrated. A great job done!.",
    },
  ];

  return (
    <>
      <section id="testimonials" className="testimonials">
        <div className="title">
          <h2>Testimonials</h2>
        </div>
        <div className="container">
          <Swiper
            modules={[Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            autoplay={{ delay: 3000 }}
            breakpoints={{
              1024: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
          >
            {testimonialsData.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="card testimonial-card text-center p-4 border-0">
                  <div className="testimonial-image-wrapper">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="testimonial-image rounded-circle"
                    />
                  </div>
                  <div className="card-body">
                    <p className="card-text">{testimonial.text}</p>
                    <h5 className="card-title">{testimonial.name}</h5>
                    <p className="card-subtitle text-muted">
                      {testimonial.position}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
}

export default Testimonials;
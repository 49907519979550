import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required email address"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Required password"),
    }),
    onSubmit: async (values) => {
      try {
        // const response = await axios.post("/api/login", values);
        console.log(values, 'loginData');
        
        toast.success("Login successful!");
      } catch (error) {
        toast.error("Login failed. Please try again.");
      }
    },
  });

  return (
    <div className="mt-3">
      <form onSubmit={formik.handleSubmit}>
        {/* <label htmlFor="email">Email address</label> */}
        <div className="input-group input-group-border">
          <input
            type="email"
            className="form-control no-border"
            id="email"
            name="email"
            placeholder="Enter email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
        </div>
        {formik.touched.email && formik.errors.email ? (
          <div className="error text-danger">{formik.errors.email}</div>
        ) : null}
        {/* <label htmlFor="password" className="mt-3">Password</label> */}
        <div className="input-group input-group-border mt-3">
          <input
            type="password"
            className="form-control no-border"
            id="password"
            name="password"
            placeholder="Enter password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
        </div>
        {formik.touched.password && formik.errors.password ? (
          <div className="error text-danger">{formik.errors.password}</div>
        ) : null}
        <button type="submit" className="btn btn-connect full-width-btn mt-3">
          Login
        </button>
      </form>
    </div>
  );
}

export default Login;

import React from "react";
import { Button } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
// import { FaRegStar } from "react-icons/fa";
// import { FiClock } from "react-icons/fi";
// import img1 from "../../assets/img/course/course1.png";
// import img2 from "../../assets/img/course/course2.png";
// import img3 from "../../assets/img/course/course3.png";
import arrow1 from "../../assets/img/arrows.png";
import arrow2 from "../../assets/img/arrowb.png";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { GiAtom } from "react-icons/gi";
import { AiOutlinePython } from "react-icons/ai";
import { TbUxCircle } from "react-icons/tb";
import { FaLaptopCode } from "react-icons/fa";
import { FaBezierCurve } from "react-icons/fa6";
import { Link } from "react-router-dom";

function Course() {
  // const courses = [
  //   {
  //     img: img1,
  //     title: "Web Development",
  //     duration: "6 Months",
  //     rating: "4.2 (1,000)",
  //   },
  //   {
  //     img: img2,
  //     title: "Data Science",
  //     duration: "8 Months",
  //     rating: "4.5 (2,000)",
  //   },
  //   {
  //     img: img3,
  //     title: "Data Science",
  //     duration: "8 Months",
  //     rating: "4.5 (2,000)",
  //   },
  // ];

  return (
    <>
      <section id="courses" className="courses">
        <div className="container">
          <div className="title">
            <h2>Our Specialization Courses</h2>
          </div>
          {/* <div className="courses-card">
            <div className="row">
              {courses.map((course, index) => (
                <div className="col-md-4" key={index}>
                  <div className="card card-1">
                    <img src={course.img} alt="img" />
                  </div>
                  <div className="card card-2">
                    <div className="description">
                      <h5>{course.title}</h5>
                      <div className="time">
                        <div className="time-2">
                          <FiClock className="mx-2" />
                          <p>{course.duration}</p>
                        </div>
                        <div className="rating">
                          <FaRegStar className="mx-2 star" />
                          <p>{course.rating}</p>
                        </div>
                      </div>
                      <div className="courses-btn">
                        <Button className="button">
                          Know more <IoIosArrowForward />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div> */}

          <div className="course-code">
            <div className="code-title">
              <div className="code-text">
                <img src={arrow1} alt="" />
                <h6>Coding</h6> <img src={arrow2} alt="" />
              </div>
            </div>
            <div className="row course-code-row">
              <div className="col-lg-4 col-md-6 col-sm-12 course-code-col">
                <div className="card">
                  <div className="d-flex justify-content-center">
                    <div className="avatar">
                      <FaLaptopCode className="icon" />
                    </div>
                  </div>
                  <div className="description">
                    <h5 className="mx-3">Front End Development</h5>
                    <h6>Course Price: ₹25,000</h6>
                  </div>
                  <div className="desc-sect">
                    <Button className="btn-desc">4 Classes</Button>
                    <Button className="btn-desc">9 Project</Button>
                    <Button className="btn-desc">45 Lessons</Button>
                    <Button className="btn-desc">22 Videos</Button>
                  </div>
                  <div className="description-btn">
                    <Link to="/coursedetails/1">
                      <Button className="btn-more">
                        Learn more <IoIosArrowForward />
                      </Button>
                    </Link>
                    <br />
                    <Link to="/enrollpage">
                      <Button className="btn-now">
                        Enroll Now <IoIosArrowForward />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 course-code-col">
                <div className="card">
                  <div className="d-flex justify-content-center">
                    <div className="avatar avtar-1">
                      <GiAtom className="icon" />
                    </div>
                  </div>
                  <div className="description">
                    <h5 className="mx-3">Front End Development +React Js</h5>
                    <h6>Course Price: ₹20,000</h6>
                  </div>
                  <div className="desc-sect">
                    <Button className="btn-desc">4 Classes</Button>
                    <Button className="btn-desc">9 Project</Button>
                    <Button className="btn-desc">45 Lessons</Button>
                    <Button className="btn-desc">22 Videos</Button>
                  </div>
                  <div className="description-btn">
                    <Link to="/coursedetails/2">
                      <Button className="btn-more">
                        Learn more <IoIosArrowForward />
                      </Button>
                    </Link>
                    <br />
                    <Link to="/enrollpage">
                      <Button className="btn-now">
                        Enroll Now <IoIosArrowForward />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 course-code-col">
                <div className="card">
                  <div className="d-flex justify-content-center">
                    <div className="avatar avtar-2">
                      <AiOutlinePython className="icon" />
                    </div>
                  </div>
                  <div className="description">
                    <h5 className="mx-3">Python For Web Apps & Data</h5>
                    <h6>Course Price: ₹30,000</h6>
                  </div>
                  <div className="desc-sect">
                    <Button className="btn-desc">4 Classes</Button>
                    <Button className="btn-desc">9 Project</Button>
                    <Button className="btn-desc">45 Lessons</Button>
                    <Button className="btn-desc">22 Videos</Button>
                  </div>
                  <div className="description-btn">
                    <Link to="/coursedetails/3">
                      <Button className="btn-more">
                        Learn more <IoIosArrowForward />
                      </Button>
                    </Link>
                    <br />
                    <Link to="/enrollpage">
                      <Button className="btn-now">
                        Enroll Now <IoIosArrowForward />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 course-code-col">
                <div className="card">
                  <div className="d-flex justify-content-center">
                    <div className="avatar avtar-3">
                      <TbUxCircle className="icon" />
                    </div>
                  </div>
                  <div className="description">
                    <h5 className="mx-3">User Experience (UX)</h5>
                    <h6>Course Price: ₹15,000</h6>
                  </div>
                  <div className="desc-sect">
                    <Button className="btn-desc">4 Classes</Button>
                    <Button className="btn-desc">9 Project</Button>
                    <Button className="btn-desc">45 Lessons</Button>
                    <Button className="btn-desc">22 Videos</Button>
                  </div>
                  <div className="description-btn">
                    <Link to="/coursedetails/4">
                      <Button className="btn-more">
                        Learn more <IoIosArrowForward />
                      </Button>
                    </Link>
                    <br />
                    <Link to="/enrollpage">
                      <Button className="btn-now">
                        Enroll Now <IoIosArrowForward />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 course-code-col">
                <div className="card">
                  <div className="d-flex justify-content-center">
                    <div className="avatar avtar-4">
                      <FaBezierCurve className="icon" />
                    </div>
                  </div>
                  <div className="description">
                    <h5 className="mx-3">Visual Design</h5>
                    <h6>Course Price: ₹25,000</h6>
                  </div>
                  <div className="desc-sect">
                    <Button className="btn-desc">4 Classes</Button>
                    <Button className="btn-desc">9 Project</Button>
                    <Button className="btn-desc">45 Lessons</Button>
                    <Button className="btn-desc">22 Videos</Button>
                  </div>
                  <div className="description-btn">
                    <Link to="/coursedetails/5">
                      <Button className="btn-more">
                        Learn more <IoIosArrowForward />
                      </Button>
                    </Link>
                    <br />
                    <Link to="/enrollpage">
                      <Button className="btn-now">
                        Enroll Now <IoIosArrowForward />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="course-apti course-code">
            <div className="apti-title">
              <div className="apti-text">
                <img src={arrow1} alt="" />
                <h6>Aptitude Prep</h6> <img src={arrow2} alt="" />
              </div>
            </div>
            <div className="row course-code-row">
              <div className="col-lg-4 col-md-6 col-sm-12 course-code-col">
                <div className="card">
                  <div className="d-flex justify-content-center">
                    <div className="avatar">
                      <h4 className="icon">E</h4>
                    </div>
                  </div>
                  <div className="description">
                    <h5 className="mx-3">Campus Express</h5>
                    <h6>Course Price: ₹5,400</h6>
                  </div>
                  <div className="desc-sect">
                    <Button className="btn-desc">4 Classes</Button>
                    <Button className="btn-desc">9 Project</Button>
                    <Button className="btn-desc">45 Lessons</Button>
                    <Button className="btn-desc">22 Videos</Button>
                  </div>
                  <div className="description-btn">
                    <Button className="btn-more">
                      Learn more <IoIosArrowForward />
                    </Button>
                    <br />
                    <Link to="/enrollpage">
                      <Button className="btn-now">
                        Enroll Now <IoIosArrowForward />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 course-code-col">
                <div className="card">
                  <div className="d-flex justify-content-center">
                    <div className="avatar avtar-1">
                      <h4 className="icon">M</h4>
                    </div>
                  </div>
                  <div className="description">
                    <h5 className="mx-3">Campus Mock Test</h5>
                    <h6>Course Price: ₹7,200</h6>
                  </div>
                  <div className="desc-sect">
                    <Button className="btn-desc">4 Classes</Button>
                    <Button className="btn-desc">9 Project</Button>
                    <Button className="btn-desc">45 Lessons</Button>
                    <Button className="btn-desc">22 Videos</Button>
                  </div>
                  <div className="description-btn">
                    <Button className="btn-more">
                      Learn more <IoIosArrowForward />
                    </Button>
                    <br />
                    <Link to="/enrollpage">
                      <Button className="btn-now">
                        Enroll Now <IoIosArrowForward />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 course-code-col">
                <div className="card">
                  <div className="d-flex justify-content-center">
                    <div className="avatar avtar-2">
                      <h4 className="icon">C</h4>
                    </div>
                  </div>
                  <div className="description">
                    <h5 className="mx-3">Campus Code X</h5>
                    <h6>Course Price: ₹7,200</h6>
                  </div>
                  <div className="desc-sect">
                    <Button className="btn-desc">4 Classes</Button>
                    <Button className="btn-desc">9 Project</Button>
                    <Button className="btn-desc">45 Lessons</Button>
                    <Button className="btn-desc">22 Videos</Button>
                  </div>
                  <div className="description-btn">
                    <Button className="btn-more">
                      Learn more <IoIosArrowForward />
                    </Button>
                    <br />
                    <Link to="/enrollpage">
                      <Button className="btn-now">
                        Enroll Now <IoIosArrowForward />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 course-code-col">
                <div className="card">
                  <div className="d-flex justify-content-center">
                    <div className="avatar avtar-3">
                      <h4 className="icon">P</h4>
                    </div>
                  </div>
                  <div className="description">
                    <h5 className="mx-3">Campus Prime X</h5>
                    <h6>Course Price: ₹8,400</h6>
                  </div>
                  <div className="desc-sect">
                    <Button className="btn-desc">4 Classes</Button>
                    <Button className="btn-desc">9 Project</Button>
                    <Button className="btn-desc">45 Lessons</Button>
                    <Button className="btn-desc">22 Videos</Button>
                  </div>
                  <div className="description-btn">
                    <Button className="btn-more">
                      Learn more <IoIosArrowForward />
                    </Button>
                    <br />
                    <Link to="/enrollpage">
                      <Button className="btn-now">
                        Enroll Now <IoIosArrowForward />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="courses-card">
            <Swiper
              spaceBetween={100}
              slidesPerView={1}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              breakpoints={{
                425: {
                  slidesPerView: 1,
                  spaceBetween: 80,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 100,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 100,
                },
              }}
            >
              {courses.map((course, index) => (
                <SwiperSlide key={index}>
                  <div className="col-md-4">
                    <div className="card card-1">
                      <img src={course.img} alt="img" />
                    </div>
                    <div className="card card-2">
                      <div className="description">
                        <h5>{course.title}</h5>
                        <div className="time">
                          <div className="time-2">
                            <FiClock className="mx-2" />
                            <p>{course.duration}</p>
                          </div>
                          <div className="rating">
                            <FaRegStar className="mx-2 star" />
                            <p>{course.rating}</p>
                          </div>
                        </div>
                        <div className="courses-btn">
                          <Button className="button">
                            Know more <IoIosArrowForward />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div> */}
        </div>
      </section>
    </>
  );
}

export default Course;

import React from "react";
import "./index.css";
import Image1 from "./img/Mask group.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";

function Index() {
  return (
    <div id="let_connect" className="mx-0">
      <div className="py-5 px-5 row mx-0">
        <div className="col-sm-12 col-md-6 left-section px-5">
          <h1 className="hdl-lc">Ready to Find Your Next Intern?</h1>
          <div className="pt-5">
            <Link to="/enrollpage">
              <button className="btn btn-custom px-5 py-3" type="submit">
                Lets Connect <i className="bi bi-chevron-compact-right"></i>
              </button>
            </Link>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 left-section px-5 d-flex justify-content-center align-items-center hide-on-mobile">
          <img
            src={Image1}
            alt="Graphic showing recruitment process"
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  );
}

export default Index;

/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import img from "../../../assets/img/courseoverview/courseover1.png";
import img2 from "../../../assets/img/courseoverview/coursedetails1.png";
import "./index.css";
import { DiClojure } from "react-icons/di";
import cousredetails from "../../../coursedetails.json" 
import { useParams } from "react-router-dom";

function index() {
  const { id } = useParams();
  const course = cousredetails.find((course) => course.id === parseInt(id));
  return (
    <section id="course-overview" className="course-overview">
      <div className="title">
        <h2>Course Overview</h2>
      </div>
      <div className="container">
        <div className="row text-course-row">
          <div className="col-lg-6 col-md-12 col-sm-12 text-course-div">
            <p className="sub-course">
              {course.subHead1}
            </p>
            <p className="sub-course">
            {course.subHead2}
            </p>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 text-course-img">
            <img src={img} alt="" />
          </div>
        </div>

        <div className="d-flex justify-content-center card-div-purple">
          <div className="card card1">
            <div className="row">
              <div className="col-lg-6">
                <div className="purple">
                  <h5>Who is it for?</h5>
                </div>
              </div>
              <div className="col-lg-6 d-flex">
                <div className="purple2">
                  <img src={img2} alt="" />
                  <h5>College Students, Graduates</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center skill-card-overview">
          <div className="card card2">
            <h5>Skills & Software You Will Learn</h5>
            <div className="row skill-row">
              <div className="col-12 col-lg-3 col-md-4 mb-3 skill-col">
                <div className="text-center rounded skill-item">
                  <span className="icon"><DiClojure/></span>{" "}
                  <h6>{course.skill1}</h6>
                </div>
              </div>
              <div className="col-12 col-lg-3 col-md-4 mb-3 skill-col">
                <div className="text-center rounded skill-item">
                  <span className="icon"><DiClojure/></span>
                  <h6>{course.skill2}</h6>
                </div>
              </div>
              <div className="col-12 col-lg-3 col-md-4 mb-3 skill-col">
                <div className="text-center rounded skill-item">
                  <span className="icon"><DiClojure/></span>
                  <h6>{course.skill3}</h6>
                </div>
              </div>
              <div className="col-12 col-lg-3 col-md-4 mb-3 skill-col">
                <div className="text-center rounded skill-item">
                  <span className="icon"><DiClojure/></span>
                  <h6>{course.skill4}</h6>
                </div>
              </div>
              <div className="col-12 col-lg-3 col-md-4 mb-3 skill-col">
                <div className="text-center rounded skill-item">
                  <span className="icon"><DiClojure/></span>
                  <h6>{course.skill5}</h6>
                </div>
              </div>
              <div className="col-12 col-lg-3 col-md-4 mb-3 skill-col">
                <div className="text-center rounded skill-item">
                  <span className="icon"><DiClojure/></span>
                  <h6>{course.skill6}</h6>
                </div>
              </div>
              <div className="col-12 col-lg-3 col-md-4 mb-3 skill-col">
                <div className="text-center rounded skill-item">
                  <span className="icon"><DiClojure/></span>
                  <h6>{course.skill7}</h6>
                </div>
              </div>
              <div className="col-12 col-lg-3 col-md-4 mb-3 skill-col">
                <div className="text-center rounded skill-item">
                  <span className="icon"><DiClojure/></span>
                  <h6>{course.skill8}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default index;

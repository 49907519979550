import React from 'react'
import PrivacyPolicy from '../../components/PrivacyPolicy/index'

function index() {
  return (
    <div>
      <PrivacyPolicy/>
    </div>
  )
}

export default index
